import React, { useEffect, useRef, useState } from "react"
import "./footer.scss"
import { Form, FloatingLabel, FormGroup } from "react-bootstrap"
import axios from "../../http/axios"
import { useForm } from "react-hook-form"
import ReCAPTCHA from "react-google-recaptcha"

import { isBrowser } from "../../services/Utils"

const Footer = (props) => {
  const reRef = useRef()
  const [recaptcha, setRecaptcha] = useState([])
  const [errorName, setErrorName] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorPhone, setErrorPhonee] = useState(false)
  const [staticContents, setStaticContents] = useState([])
  const [contactsPage, setContactsPage] = useState([])
  const { register, handleSubmit, reset, formState } = useForm()

  useEffect(() => {
    setStaticContents(props.staticContactsForm)
    setContactsPage(props.staticContactsPage)
    setRecaptcha(props.recaptchaSettings)
  }, [])

  const redoCaptcha = () => {
    reRef.current.reset()
  }

  const handleChangeUserName = (event, defaultName) => {
    const value = event.target.value

    if (defaultName === "name") {
      if (value.includes("http://") || value.includes("https://") || value.includes(".")) {
        setErrorName(true)
      } else {
        setErrorName(false)
      }
    }

    if (defaultName === "message") {
      if (value.includes("http://") || value.includes("https://")) {
        setErrorMessage(true)
      } else {
        setErrorMessage(false)
      }
    }

    if (defaultName === "phone") {
      var regex = /^[ \-+0-9()]+$/;
      if (regex.test(value)) {
        setErrorPhonee(false)
      } else {
        setErrorPhonee(true)
      }
    }

  }

  const onSubmit = data => {

    if (data.name && data.phone && data.message && !errorName && !errorMessage && !errorPhone) {
      reRef.current.executeAsync().then((res) => {
        const body = { ...data, ...{ recaptchaToken: res } }
        axios.post("/contact-us", body).then(res => {
          if (res.status === 200) {
            reset()
            alert(staticContents["Success"])
          }
          redoCaptcha()

        }).catch(error => {
          alert(error.response.data.message)
        })
      })
    } else {
      alert(staticContents["Fill all fields error"])
    }
  }

  //----Expand textarea with content
  const autoExpand = field => {
    field.style.height = "inherit"

    const computed = window.getComputedStyle(field)

    const height =
      parseInt(computed.getPropertyValue("border-top-width"), 5) +
      parseInt(computed.getPropertyValue("padding-top"), 5) +
      field.scrollHeight +
      parseInt(computed.getPropertyValue("padding-bottom"), 5) +
      parseInt(computed.getPropertyValue("border-bottom-width"), 5)

    field.style.height = height + "px"
  }

  if (isBrowser()) {
    document.addEventListener(
      "input",
      event => {
        if (event.target.tagName.toLowerCase() !== "textarea") return
        autoExpand(event.target)
      },
      false
    )
  }

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="form-wrapper row pt-5">
            <Form onSubmit={handleSubmit(onSubmit)} className="col-lg-7 mb-4">
              <h1>{staticContents["Header"]}</h1>
              <div className="footer-text-block">{staticContents["Description"]}</div>
              <FormGroup controlId="name">
                <FloatingLabel
                  controlId="name"
                  label={staticContents["Field: Name"]}
                  className="footer-text-block mb-3 mt-5"
                >
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    onInput={e => handleChangeUserName(e, "name")}
                    {...register("name")}
                    className="curs-P"
                  />
                  {errorName && <span style={{ color: "red" }}>Невірний формат імені</span>}
                </FloatingLabel>
              </FormGroup>
              <FloatingLabel
                controlId="phone"
                label={staticContents["Field: Phone"]}
                className="footer-text-block mb-3"
              >
                <Form.Control
                  type="text"
                  {...register("phone")}
                  onInput={e => handleChangeUserName(e, "phone")}
                  placeholder="Phone"
                  className="curs-P"
                />
                {errorPhone && <span style={{ color: "red" }}>Невірний формат номеру телефону</span>}

              </FloatingLabel>
              <FloatingLabel
                controlId="message"
                label={staticContents["Field: Message"]}
                className="footer-text-block mb-4"
              >
                <Form.Control
                  as="textarea"
                  {...register("message")}
                  onInput={e => handleChangeUserName(e, "message")}
                  placeholder="Message"
                  className="curs-P"
                />
                {errorMessage && <span style={{ color: "red" }}>Невірний формат повідомлення</span>}
              </FloatingLabel>
              <button
                disabled={!formState.isDirty}
                type="submit"
                className="btn btn-primary btn-lg  col-lg-5 mb-4"
              >
                {staticContents["Submit Button Text"]}
              </button>
              <div dangerouslySetInnerHTML={{ __html: staticContents["Submit Privacy HTML"] }}
                   className="footer-text-block privacy-text-block">
              </div>
            </Form>
            <div className="col" />
          </div>
          <div className="navigation__left d-flex justify-content-around mb-4">
            <div className="navigation__item facebook-link">
              <a target="_blank" href="https://www.facebook.com/bigmediaua">
                Facebook
              </a>
            </div>
            <div className="navigation__item instagram-link">
              <a
                target="_blank"
                href="https://www.instagram.com/bigmedia.ua/"
              >
                Instagram
              </a>
            </div>
            <div className="navigation__item youtube-link">
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCyAy79L9mqFmMcoLOe4mV-g"
              >
                Youtube
              </a>
            </div>
            <div className="navigation__item linkedin-link">
              <a
                target="_blank"
                href="https://www.linkedin.com/company/bigmediaua"
              >
                LinkedIn
              </a>
            </div>
          </div>
          <div className="row bottom-part d-flex align-items-center">
            <div className="right-reserved d-flex col-12 col-sm-3 col-md-3 col-lg-6">
              <div className="">
                © 2022 BigMedia. <br /> All Rights Reserved
              </div>
            </div>
            <div className="address-n-feedback d-flex gap-7 col-12 col-sm-9 col-md-9 col-lg-6">
              <div className="row w-100">
                <div className="col-6" dangerouslySetInnerHTML={{ __html: contactsPage["Address Text"] }}>
                </div>

                <div className="col-6">
                  <div dangerouslySetInnerHTML={{ __html: contactsPage["Phone HTML"] }} />
                  <span dangerouslySetInnerHTML={{ __html: contactsPage["Email HTML"] }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {recaptcha && recaptcha?.enabled && (
        <div>
          <ReCAPTCHA
            sitekey={recaptcha.publicKey}
            size="invisible"
            ref={reRef}
            onExpired={redoCaptcha}
          />
        </div>
      )}
    </>
  )
}
export default Footer
