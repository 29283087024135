import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./header.scss";
import "./menu-modal.scss";
import { StaticImage } from "gatsby-plugin-image";
import { Modal } from "react-bootstrap";
import { isBrowser } from "../../services/Utils";

import HeaderItemPopup from "../../shared/header-item-popup/HeaderItemPopup";

const Header = ({
  menu,
  displayLogoLinks,
  isModal,
  closeModalFunction,
  contents,
}) => {
  const [staticContents, setStaticContents] = useState([]);
  const [logoLinks, setLogoLinks] = useState(displayLogoLinks);

  const displayLogoLinksClass = logoLinks ? "links-active" : "";

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [langs, setLangs] = useState([
    {
      key: "uk",
      label: "UA",
    },
    {
      key: "en",
      label: "ENG",
    },
  ]);
  const [currLang, setCurrLang] = useState(undefined);

  useEffect(() => {
    if (contents) {
      setStaticContents(contents);
    }
    if (isBrowser()) {
      const url = window.location.pathname;
      let lang = langs[0].key;
      const paths = url.split("/");
      paths.shift();

      if (paths[0].length === 2) {
        lang = paths[0];
      }
      setCurrLang(lang);
    }
  }, []);

  let sortedMenu = [];
  if (menu) {
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].level === 1) {
        sortedMenu.push(menu[i]);
        sortedMenu[sortedMenu.indexOf(menu[i])].popup = [];
      } else {
        sortedMenu[sortedMenu.length - 1].popup.push(menu[i]);
      }
    }
    if (sortedMenu && sortedMenu.length > 0) {
      sortedMenu.sort((a, b) => {
        return a?.position - b?.position;
      });
    }
  }

  menu &&
    menu.sort(function (a, b) {
      return a?.position - b?.position;
    });

  const onChangeLang = (e) => {
    if (isBrowser()) {
      const value = e.target.value;
      if (value !== currLang) {
        const url = window.location.pathname;
        const paths = url.split("/");
        paths.shift();
        if (paths[0].length === 2) {
          paths.shift();
        } else {
          paths.unshift(value);
        }
        window.location.href = `/${paths.filter((x) => x !== "").join("/")}`;
      }
    }
  };

  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }

  const handleClose = () => setShow(false);

  const closeModal = () => {
    handleClose();
  };

  const composeModalMenu = (menu) => {
    if (menu && menu.length) {
      return menu.map((item, index) => {

        if (item.level !== 1 && item.url.includes("http")) {
          return (
            <h3
              key={item.id}
              className={`${item.level === 2 ? "site-page" : ""
                } modal-nav-item mb-3 curs-P`}
            >
              <a href={item.url} target="_blank">
                {item.title}
              </a>
            </h3>)
        }
        if (item.level !== 1 || ((menu[index] === menu.length - 1) || (menu[index + 1]?.level !== 2))) {
          return (
            <Link
              key={item.id}
              to={item.url}
              onClick={() => closeModal()}
            >
              <h3
                className={`${item.level === 2 ? "site-page" : ""
                  } modal-nav-item mb-3 curs-P`}
              >
                {item.title}
              </h3>
            </Link>
          )
        }
        return (
          <h3
            key={item.id}
            className={`modal-nav-item mb-3 curs-P`}
          >
            {item.title}
          </h3>
        )
      })
    }
  }

  return (
    <div className="container">
		<script
		  dangerouslySetInnerHTML={{
			__html: `
			  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-M7T6VRH');
			`,
		  }}
		/>	
		<header className="header row align-items-center">
        <div
          className={`header-content-wrapper ${displayLogoLinksClass} col-6 col-sm-6 col-lg-2`}
        >
          <div className="logo-wrapper">
            <Link to="/">
              <div className="logo curs-P"></div>
            </Link>

            <div
              className={`external-links-wrapper ${displayLogoLinksClass} justify-content-between align-item-center`}
            >
              <div className="external-link">BigBoard</div>
              <div className="external-link">|</div>
              <div className="external-link">JCDecaux</div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="navigation d-flex justify-content-between">
            {isModal ? (
              <div className="navigation__left d-flex gap-4">
                <div className="navigation__item facebook-link">
                  <a target="_blank" href="https://www.facebook.com/bigmediaua">
                    facebook
                  </a>
                </div>
                <div className="navigation__item instagram-link">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/bigmedia.ua/"
                  >
                    instagram
                  </a>
                </div>
                <div className="navigation__item youtube-link">
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCyAy79L9mqFmMcoLOe4mV-g"
                  >
                    youtube
                  </a>
                </div>
                <div className="navigation__item linkedin-link">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/bigmediaua"
                  >
                    linkedin
                  </a>
                </div>
              </div>
            ) : (
              <div className="navigation__left d-flex align-items-center gap-4 visible-header-links">
                {sortedMenu && sortedMenu.length > 0 && (
                  <>
                    {sortedMenu.slice(0, 5).map((menuItem) => {
                      if (menuItem.popup.length > 0) {
                        return (
                          <HeaderItemPopup
                            menuItem={menuItem}
                            key={menuItem.id}
                          />
                        );
                      } else {
                        return menuItem.url.includes("http") ? (
                          <div key={menuItem.id} className="navigation__item">
                            <a href={menuItem.url} target="_blank">
                              {menuItem.title}
                            </a>
                          </div>
                        ) : (
                          <Link key={menuItem.id} to={menuItem.url}>
                            <div className="navigation__item">
                              {menuItem.title}
                            </div>
                          </Link>
                        );
                      }
                    })}
                  </>
                )}
              </div>
            )}
            <div className="navigation__right d-flex gap-5">
              {isModal ? (
                <div
                  className="close-btn d-flex align-items-center"
                  onClick={() => closeModalFunction()}
                >
                  <button
                    type="button"
                    className="btn-close"
                    aria-label="Close"
                  />
                </div>
              ) : (
                <div className="navigation__item align-items-center phone-number">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: staticContents["Header Phone HTML"],
                    }}
                  />
                </div>
              )}
              {!isModal && (
                <>
                  <div
                    className="navigation__item d-flex align-items-center"
                    style={{
                      position: "relative",
                    }}
                  >
                    <select
                      value={currLang}
                      onChange={onChangeLang}
                      className="languages-dropdown"
                      style={{
                        width: "100%",
                        minWidth: "50px",
                        zIndex: "2",
                        marginRight: `${currLang && currLang === "en" ? "10px" : ""
                          }`,
                      }}
                    >
                      {langs?.map((l) => (
                        <option
                          key={l.key}
                          value={l.key}
                          disabled={l.key === currLang}
                          className="lang-option"
                        >
                          {l.label}
                        </option>
                      ))}
                    </select>

                    {currLang && (
                      <img
                        src={`/${currLang}/flags/${currLang}.svg`}
                        className={"mb-0"}
                        width={20}
                        height={20}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="logo"
                        style={{
                          position: "absolute",
                          right: "0",
                          zIndex: "1",
                        }}
                      />
                    )}
                  </div>

                  <div
                    className="navigation__item d-flex align-items-center gap-2 menu-burger"
                    onClick={() => handleShow(true)}
                  >
                    <StaticImage
                      src="../../icons/menu-icon.svg"
                      width={18}
                      height={12}
                      formats={["PNG"]}
                      alt="Menu icon"
                    />
                  </div>
                </>
              )}
              <Modal show={show} fullscreen={fullscreen} onHide={handleClose}>
                <Modal.Header>
                  <div className="container">
                    <Header isModal={true} closeModalFunction={closeModal} />
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-2" />
                      <div className="col items-list">
                        {composeModalMenu(menu)}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
