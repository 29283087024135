import React, { useEffect, useState, useRef } from "react"
import "./HeaderItemPopup.scss"
import { Link } from "gatsby"
import { isBrowser } from "../../services/Utils"

function useOutsideAlerter(ref, hidePopupFunction) {
    useEffect(() => {
        if (isBrowser()) {

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    hidePopupFunction();
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [ref]);
}

const HeaderItemPopup = ({ menuItem }) => {

    const [display, setDisplay] = useState(false)
    const hidePopup = () => {
        setDisplay(false)
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, hidePopup);

    return (
        <div className="position-relative" ref={wrapperRef}>
            <div key={menuItem.id} className="navigation__item" onClick={() => setDisplay(!display)}>
                {menuItem.title}
            </div>
            <div className="flex-column header-item-popup"
                style={{ display: `${display ? "flex" : "none"}` }}
            >
                {menuItem.popup.map(item => {
                    return (
                        item.url.includes("http") ?
                            (
                                <div key={item.id} className="navigation__item">
                                    <a href={item.url} target="_blank">
                                        {item.title}
                                    </a>
                                </div>
                            ) :
                            (
                                <Link
                                    key={item.id}
                                    to={item.url}
                                >
                                    <div className="navigation__item"
                                    >
                                        {item.title}
                                    </div>
                                </Link>
                            )
                    )
                })}
            </div>
        </div>
    )
}

export default HeaderItemPopup
