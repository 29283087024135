import axios from 'axios';
import {AUTH_TOKEN_KEY} from '../constants/constants'


axios.defaults.baseURL = `${process.env.API_URL}` // the prefix of the URL
axios.defaults.headers.get['Accept'] = 'application/json'   // default header for all get request
axios.defaults.headers.get['Content-Type'] = 'application/json'   // default header for all get request
axios.defaults.headers.post['Accept'] = 'application/json'  // default header for all POST request
axios.defaults.headers.common['Authorization'] = `Bearer ${getAuthToken()}`;


axios.interceptors.request.use(function (config) {
  config.headers.Authorization = `Bearer ${getAuthToken()}`;

  return config;
});

function getAuthToken() {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(AUTH_TOKEN_KEY) || '';
  } else {
    return '';
  }
}

export default axios;
